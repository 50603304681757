import { Button, Divider, Grid, Menu, MenuItem, Paper, styled, Tab, Tabs } from "@mui/material";
import { AdminOpportunityDto, ChangeAction, IdeaStatus, OpportunityChangeAction } from "api-shared";
import { TFunction } from "i18next";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CellProps, Column, Row } from "react-table";
import SearchInput from "../../../components/input/SearchInput";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import BaseTable from "../../../components/table/BaseTable";
import TableDateCell from "../../../components/table/TableDateCell";
import TableHeaderCell from "../../../components/table/TableHeaderCell";
import TableLinkCell from "../../../components/table/TableLinkCell";
import TableSettingsButton from "../../../components/table/TableSettingsButton";
import TableTextCell from "../../../components/table/TableTextCell";
import TableUserCell from "../../../components/table/TableUserCell";
import { useAdminOpportunities, useAdminUpdateOpportunitiesMutation } from "../../../domain/admin/opportunities";
import { useAdminUsers } from "../../../domain/admin/users";
import { useDebounce } from "../../../hooks/useDebounce";
import useMenu from "../../../hooks/useMenu";
import { RouteFor } from "../../../lib/routes";
import { translationKeys } from "../../../translations/main-translations";
import { useIdeaStatusTranslation } from "../../ideas/hooks/useIdeaStatusTranslation";

enum VisibilityTabs {
    Active = "active",
    Archived = "archived",
}

const Grow = styled(Grid)({
    flexGrow: 1,
    flexShrink: 1,
    height: 0, // work around bug in chrome 69/safari with unscollable page
});

const Root = styled(Paper)({ height: "100%" });
const RootGrid = styled(Grid)({ height: "100%" });

const TabWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(1, 3, 0, 3),
}));

function getMenuOptions(
    opps: AdminOpportunityDto[],
    menuOppId: number | null,
    translate: TFunction,
): { action: OpportunityChangeAction; name: string }[] {
    const selectedOpp = opps.find(({ id }) => id === menuOppId);
    if (menuOppId == null || selectedOpp == null) {
        return [];
    }
    const isArchivedOpp = selectedOpp.deletedAt !== null;

    const availableOptions = isArchivedOpp
        ? [
              {
                  action: "UNARCHIVE_OPP" as OpportunityChangeAction,
                  name: translate("setUnarchive"),
              },
          ]
        : [
              {
                  action: "ARCHIVE_OPP" as OpportunityChangeAction,
                  name: translate("setArchive"),
              },
          ];

    if (selectedOpp.status === IdeaStatus.DISCARDED && !isArchivedOpp) {
        availableOptions.push({
            action: "REOPEN_OPP" as OpportunityChangeAction,
            name: translate(translationKeys.VDLANG_ADMIN_OPPS_REOPEN_OPP),
        });
    }

    return availableOptions;
}

const renderTableLinkCell = (props: CellProps<AdminOpportunityDto>) => (
    <TableLinkCell {...props} link={RouteFor.opportunities.forId(props.row.original.id)}>
        {props.value}
    </TableLinkCell>
);

const renderTableDateCell = (props: CellProps<AdminOpportunityDto>, translate: TFunction) => (
    <TableDateCell nullValue={translate("never")} {...props} />
);

const renderSettingsButton = (
    props: CellProps<AdminOpportunityDto>,
    openMenu: (event: React.MouseEvent<HTMLButtonElement>, value: number) => void,
    translate: TFunction,
) => <TableSettingsButton title={translate("Edit")} onClick={(e) => openMenu(e, props.value)} data-testid={`settings${props.value}`} />;

const defaultSort = [{ id: "displayId", desc: false }];

const OppsSettings = () => {
    const oppsQuery = useAdminOpportunities();
    const usersQuery = useAdminUsers();
    const updateOppsMutation = useAdminUpdateOpportunitiesMutation();
    const translateIdeaStatus = useIdeaStatusTranslation();

    const { t: translate } = useTranslation();

    const [activeTab, setActiveTab] = useState(VisibilityTabs.Active);
    const [menuOppId, setMenuOppId] = useState<number | null>(null);
    const resetMenuOppId = useCallback(() => setMenuOppId(null), []);

    const oppMenu = useMenu({ onClose: resetMenuOppId });
    // extract open function local variable, so that the linter is able to correctly track the dependencies of useCallback
    const openMenu = oppMenu.open;
    const openOppMenu = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>, value: number) => {
            openMenu(event);
            setMenuOppId(value);
        },
        [openMenu],
    );

    // controlled table state
    const [selectedOppIds, setSelectedOppIds] = useState<number[]>([]);

    const [filter, setFilter] = useState("");
    const tableFilterValue = useDebounce(filter, 300);

    const columns = useMemo<Column<AdminOpportunityDto>[]>(
        () => [
            {
                Header: TableHeaderCell,
                accessor: "displayId",
                label: "ID",
                width: 75,
                Cell: TableTextCell,
            },
            {
                Header: TableHeaderCell,
                accessor: "title",
                label: translate("title"),
                Cell: (props) => renderTableLinkCell(props),
            },
            {
                Header: TableHeaderCell,
                accessor: (o) => translateIdeaStatus(o.status),
                id: "status",
                label: translate("status"),
                Cell: TableTextCell,
            },
            {
                Header: TableHeaderCell,
                accessor: "ownerId",
                id: "assignedToId",
                label: translate("assignedToId"),
                disableResizing: true,
                width: 250,
                sortType: "user",
                Cell: (props) => <TableUserCell users={usersQuery.data} {...props} />,
            },
            {
                Header: TableHeaderCell,
                id: "lastModificationAt",
                accessor: "lastModificationAt",
                label: translate("last_modification"),
                Cell: (props) => renderTableDateCell(props, translate),
            },
            {
                id: "settings",
                accessor: "id",
                disableSortBy: true,
                disableResizing: true,
                disableGlobalFilter: true,
                width: 64,
                Cell: (props) => renderSettingsButton(props, openOppMenu, translate),
            },
        ],
        [openOppMenu, translate, translateIdeaStatus, usersQuery.data],
    );

    const applyArchive = () => {
        updateOppsMutation.mutate({
            oppIds: selectedOppIds,
            action: ChangeAction.ARCHIVE_OPP,
        });
    };

    const applyUnarchive = () => {
        updateOppsMutation.mutate({
            oppIds: selectedOppIds,
            action: ChangeAction.UNARCHIVE_OPP,
        });
    };

    const handleSelectedRowsChanged = useCallback((newSelectedRows: Row<AdminOpportunityDto>[]) => {
        setSelectedOppIds(newSelectedRows.map((r) => r.original.id));
    }, []);

    const shownOpps = useMemo(
        () =>
            (oppsQuery.data ?? []).filter(({ deletedAt }) =>
                activeTab === VisibilityTabs.Archived ? deletedAt !== null : deletedAt === null,
            ),
        [activeTab, oppsQuery.data],
    );

    const isRowSelected = useCallback((row: AdminOpportunityDto) => selectedOppIds.includes(row.id), [selectedOppIds]);

    const oppMenuOptions = getMenuOptions(shownOpps, menuOppId, translate);

    const handleMenuAction = (action: OpportunityChangeAction, oppId: number) => {
        updateOppsMutation.mutate({
            oppIds: [oppId],
            action,
        });
        oppMenu.close();
    };

    const changeTab = (e: SyntheticEvent, v: VisibilityTabs) => {
        setActiveTab(v);
        setSelectedOppIds([]);
        setFilter("");
    };

    return (
        <Root>
            <RootGrid container direction="column" wrap="nowrap">
                <Grid container direction="column" wrap="nowrap" height="100%">
                    {menuOppId != null ? (
                        <Menu {...oppMenu.menuProps}>
                            {oppMenuOptions.map((option) => (
                                <MenuItem key={option.action.toString()} onClick={() => handleMenuAction(option.action, menuOppId)}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Menu>
                    ) : null}
                    <Grid item>
                        <TabWrapper>
                            <Tabs value={activeTab} onChange={changeTab}>
                                {[VisibilityTabs.Active, VisibilityTabs.Archived].map((label) => (
                                    <Tab key={label} label={translate(label)} value={label} />
                                ))}
                            </Tabs>
                        </TabWrapper>
                    </Grid>
                    <Grid item component={Divider} />
                    <Grid item>
                        <Grid container direction="row" alignItems="space-between" spacing={1} px={3} py={1.5} flexWrap="nowrap">
                            <Grid item xs={6}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={activeTab === VisibilityTabs.Active ? applyArchive : applyUnarchive}
                                    disabled={selectedOppIds.length === 0}
                                >
                                    {translate(activeTab === VisibilityTabs.Active ? "setArchive" : "setUnarchive")}
                                </Button>
                            </Grid>
                            <Grid item xs={6} container justifyContent="flex-end">
                                <SearchInput translate={translate} onChange={setFilter} searchKey={filter} fullWidth={false} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item component={Divider} />
                    <Grow item>
                        {oppsQuery.isSuccess && usersQuery.isSuccess ? (
                            <BaseTable
                                fullHeight
                                data={shownOpps}
                                columns={columns}
                                defaultCanSort
                                disableSortBy={false}
                                itemName="opportunities"
                                translate={translate}
                                defaultSortBy={defaultSort}
                                noDataText={translate(translationKeys.VDLANG_NO_OPPORTUNITIES)}
                                globalFilter={tableFilterValue}
                                isRowSelected={isRowSelected}
                                onSelectionChanged={handleSelectedRowsChanged}
                            />
                        ) : (
                            <LoadingAnimation />
                        )}
                    </Grow>
                </Grid>
            </RootGrid>
        </Root>
    );
};

export default OppsSettings;
