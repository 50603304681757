import { Button, Divider, Grid, Menu, MenuItem, Paper, styled, Tab, Tabs } from "@mui/material";
import { AclNamespaces, AclPermissions, AdminMeasureDto, MeasureStatus } from "api-shared";
import { TFunction } from "i18next";
import { debounce } from "lodash";
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CellProps, Column, Row } from "react-table";
import SearchInput from "../../../components/input/SearchInput";
import { Option } from "../../../components/input/select/types";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import BaseTable from "../../../components/table/BaseTable";
import TableDateCell from "../../../components/table/TableDateCell";
import TableHeaderCell from "../../../components/table/TableHeaderCell";
import TableLinkCell from "../../../components/table/TableLinkCell";
import TableSettingsButton from "../../../components/table/TableSettingsButton";
import TableTextCell from "../../../components/table/TableTextCell";
import TableUserCell from "../../../components/table/TableUserCell";
import {
    useAdminBulkUpdateMeasures,
    useAdminMeasureConfigs,
    useAdminMeasures,
    useAdminReopenLastGateTask,
    useAdminUpdateMeasure,
} from "../../../domain/admin/measures";
import { useFilterUsersWithPermissionOnEntity } from "../../../domain/admin/permissions";
import { useAdminUsers } from "../../../domain/admin/users";
import { useGateTaskConfigs } from "../../../domain/measure-config";
import { useUsersHavingPermissionQuery } from "../../../domain/permissions";
import { useDebounce } from "../../../hooks/useDebounce";
import useDialog from "../../../hooks/useDialog";
import useMenu from "../../../hooks/useMenu";
import { translationKeys } from "../../../translations/main-translations";
import MeasureBulkSelect from "./MeasureBulkSelect";
import { MeasuresAssigneeSelect } from "./MeasuresAssigneeSelect";
import { MeasuresChangeValuestreamDialog } from "./MeasuresChangeValuestreamDialog";

const REOPEN_MEASURE = Symbol("REOPEN_MEASURE");
const UNDISCARD_MEASURE = Symbol("UNDISCARD_MEASURE");
const REOPEN_LAST_GATE_TASK = Symbol("REOPEN_LAST_GATE_TASK");
const CHANGE_RESPONSIBILITY = Symbol("CHANGE_RESPONSIBILITY");
const CHANGE_VALUESTREAM = Symbol("CHANGE_VALUESTREAM");

const StatusLabels: Record<MeasureStatus, string> = {
    [MeasureStatus.STATUS_OPEN]: "MEASURE_STATUS_OPEN",
    [MeasureStatus.STATUS_CLOSED]: "MEASURE_STATUS_CLOSED",
    [MeasureStatus.STATUS_DISCARDED]: "MEASURE_STATUS_DISCARDED",
};

export type MeasureAction = boolean | typeof REOPEN_MEASURE | typeof UNDISCARD_MEASURE | typeof CHANGE_RESPONSIBILITY;
type ContextMenuActions = MeasureAction | typeof REOPEN_LAST_GATE_TASK | typeof CHANGE_VALUESTREAM;

enum VisibilityTabs {
    Active = "active",
    Archived = "archived",
}

const Grow = styled(Grid)({
    flexGrow: 1,
    flexShrink: 1,
    height: 0, // work around bug in chrome 69/safari with unscollable page
});

const Root = styled(Paper)({ height: "100%" });
const RootGrid = styled(Grid)({ height: "100%" });

const TabWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(1, 3, 0, 3),
}));

const getRowIdForMeasure = (row: AdminMeasureDto) => String(row.clientIid);

function getMeasureMenuOptions(measures: AdminMeasureDto[], menuMeasureId: number | null, translate: TFunction) {
    const selectedMeasure = measures.find(({ id }) => id === menuMeasureId);
    if (selectedMeasure == null) {
        return [];
    }

    const visibilityOptions: { id: ContextMenuActions; name: string }[] = [
        {
            id: true,
            name: translate("setArchive"),
        },
        {
            id: false,
            name: translate("setUnarchive"),
        },
    ];

    const availableOptions = visibilityOptions.filter(({ id }) => (selectedMeasure.deletedAt !== null) !== id);

    if (selectedMeasure.status === MeasureStatus.STATUS_DISCARDED && selectedMeasure.deletedAt === null) {
        availableOptions.push({
            id: UNDISCARD_MEASURE,
            name: translate(translationKeys.VDLANG_ADMIN_MEASURES_UNDISCARD_MEASURE),
        });
    } else if (selectedMeasure.status !== MeasureStatus.STATUS_OPEN && selectedMeasure.deletedAt === null) {
        availableOptions.push({
            id: REOPEN_MEASURE,
            name: translate(translationKeys.VDLANG_ADMIN_MEASURES_REOPEN_MEASURE),
        });
    } else if (selectedMeasure.completedGateTaskConfigId !== null && selectedMeasure.deletedAt === null) {
        // only allow reopening last gate if a gate has been completed
        availableOptions.push({
            id: REOPEN_LAST_GATE_TASK,
            name: translate(translationKeys.VDLANG_ADMIN_MEASURES_REOPEN_LAST_GATE_TASK),
        });
    }

    if (selectedMeasure.status !== MeasureStatus.STATUS_OPEN || selectedMeasure.deletedAt !== null) {
        return availableOptions;
    }

    availableOptions.push({
        id: CHANGE_VALUESTREAM,
        name: translate(translationKeys.VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM),
    });
    return availableOptions;
}

const getChangesetForAction = (actionId: MeasureAction | null, payloadId: number | null) => {
    if (actionId == null) {
        return {};
    }
    switch (actionId) {
        case REOPEN_MEASURE:
        case UNDISCARD_MEASURE:
            return { status: MeasureStatus.STATUS_OPEN };
        case CHANGE_RESPONSIBILITY:
            return { assignedToId: payloadId };
        default:
            return { archive: actionId };
    }
};

const MeasuresSettings = () => {
    const updateMeasureMutation = useAdminUpdateMeasure();
    const bulkUpdateMeasuresMutation = useAdminBulkUpdateMeasures();
    const reopenLastGateTaskMutation = useAdminReopenLastGateTask();

    const measuresQuery = useAdminMeasures();
    const usersQuery = useAdminUsers();
    const measureConfigs = useAdminMeasureConfigs();
    const gateTaskConfigs = useGateTaskConfigs();

    const { t: translate } = useTranslation();

    const changeValuestreamDialog = useDialog();

    const [activeTab, setActiveTab] = useState(VisibilityTabs.Active);
    const [selectedBulkAction, setSelectedBulkAction] = useState<MeasureAction | null>(null);
    const [newAssignedTo, setNewAssignedTo] = useState<number | null>(null);

    const [menuMeasureId, setMenuMeasureId] = useState<number | null>(null);
    const [valuestreamDialogMeasureId, setValuestreamDialogMeasureId] = useState<number | null>(null);
    const resetMenuMeasureId = useCallback(() => setMenuMeasureId(null), []);

    const { openMenu, menuProps, closeMenu } = useMenu({ onClose: resetMenuMeasureId });

    const openMeasureMenu = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>, value: number) => {
            openMenu(event);
            setMenuMeasureId(value);
        },
        [openMenu],
    );

    // controlled table state
    const [selectedMeasuresIds, setSelectedMeasuresIds] = useState<number[]>([]);
    const [selectedMeasureConfigIds, setSelectedMeasureConfigIds] = useState<number[]>([]);
    const [filter, setFilter] = useState("");
    const tableFilterValue = useDebounce(filter, 300);

    const responsiblesQuery = useUsersHavingPermissionQuery({
        permission: AclPermissions.Responsible,
        namespace: AclNamespaces.Process,
        fact: {},
    });
    const userIdsAssignableToSelectedProcesses = useFilterUsersWithPermissionOnEntity({
        permission: AclPermissions.Read,
        namespace: AclNamespaces.Valuestream,
        userIds: responsiblesQuery.data?.combinedUserIds ?? [],
        entityIds: selectedMeasureConfigIds,
        enabled: selectedBulkAction === CHANGE_RESPONSIBILITY && responsiblesQuery.isSuccess,
    });

    const usersThatCanBeResponsible =
        responsiblesQuery.data?.combinedUserIds.map((uid) => usersQuery.data?.find((u) => u.id === uid)) ?? [];

    useEffect(() => {
        if (newAssignedTo !== null && !userIdsAssignableToSelectedProcesses.data?.includes(newAssignedTo)) {
            setNewAssignedTo(null);
        }
    }, [newAssignedTo, userIdsAssignableToSelectedProcesses.data]);

    const measuresAssigneeCandidates =
        userIdsAssignableToSelectedProcesses.data !== undefined
            ? usersThatCanBeResponsible.filter((u) => u !== undefined && userIdsAssignableToSelectedProcesses.data.includes(u.id))
            : [];

    const shownMeasures = useMemo(
        () =>
            (measuresQuery.data ?? []).filter(({ deletedAt }) =>
                activeTab === VisibilityTabs.Archived ? deletedAt !== null : deletedAt === null,
            ),
        [activeTab, measuresQuery.data],
    );

    const renderSettings = useCallback(
        ({ value }: CellProps<AdminMeasureDto>) => (
            <TableSettingsButton title={translate("Edit Process")} onClick={(e) => openMeasureMenu(e, value)} />
        ),
        [openMeasureMenu, translate],
    );

    const columns = useMemo<Column<AdminMeasureDto>[]>(
        () => [
            {
                Header: TableHeaderCell,
                accessor: "clientIid",
                label: "ID",
                width: 75,
                Cell: TableTextCell,
            },
            {
                Header: TableHeaderCell,
                accessor: "title",
                label: translate("title"),
                Cell: (props) =>
                    props.row.original.deletedAt === null ? (
                        <TableLinkCell {...props} link={`/measure/${props.row.original.id}`}>
                            {props.value}
                        </TableLinkCell>
                    ) : (
                        <TableTextCell value={props.value} />
                    ),
            },
            {
                Header: TableHeaderCell,
                accessor: (r) => translate(StatusLabels[r.status]),
                id: "status",
                label: translate("status"),
                Cell: TableTextCell,
            },
            {
                Header: TableHeaderCell,
                accessor: "deletedAt",
                id: "deletedAt",
                label: translate("archived"),
                Cell: (props) => <TableDateCell nullValue={translate("never")} {...props} />,
            },
            {
                Header: TableHeaderCell,
                accessor: (row) => {
                    const measureConfig = measureConfigs.data?.find(({ id }) => id === row.measureConfigId);
                    return measureConfig !== undefined ? translate(measureConfig.name) : "";
                },
                id: "type",
                label: translate("type"),
                Cell: TableTextCell,
            },
            {
                Header: TableHeaderCell,
                accessor: ({ currentGateTaskConfigId }) => {
                    const gateTaskConfig = gateTaskConfigs.find(({ id }) => id === currentGateTaskConfigId);
                    return gateTaskConfig != null ? translate(gateTaskConfig.name) : "";
                },
                id: "level",
                label: translate(translationKeys.VDLANG_GATETASK),
                Cell: TableTextCell,
            },
            {
                Header: TableHeaderCell,
                accessor: "assignedToId",
                id: "assignedToId",
                label: translate("assignedToId"),
                disableResizing: true,
                width: 250,
                sortType: "user",
                Cell: (props) => <TableUserCell users={usersQuery.data} {...props} />,
            },
            {
                Header: TableHeaderCell,
                id: "lastModificationAt",
                accessor: "lastModificationAt",
                label: translate("last_modification"),
                Cell: (props) => <TableDateCell nullValue={translate("never")} {...props} />,
            },
            {
                id: "settings",
                accessor: "id",
                disableSortBy: true,
                disableResizing: true,
                disableGlobalFilter: true,
                width: 64,
                Cell: renderSettings,
            },
        ],
        [translate, renderSettings, measureConfigs.data, gateTaskConfigs, usersQuery.data],
    );

    const applyBulkAction = () =>
        bulkUpdateMeasuresMutation.mutate({
            measureIds: selectedMeasuresIds,
            changes: getChangesetForAction(selectedBulkAction, newAssignedTo),
        });

    const handleMenuAction = (changeId: ContextMenuActions, measureId: number | null) => {
        if (measureId === null) {
            return;
        }

        if (changeId !== REOPEN_LAST_GATE_TASK && changeId !== CHANGE_VALUESTREAM) {
            updateMeasureMutation.mutate({
                id: measureId,
                ...getChangesetForAction(changeId, newAssignedTo),
            });
        } else if (changeId === CHANGE_VALUESTREAM) {
            setValuestreamDialogMeasureId(measureId);
            changeValuestreamDialog.openDialog();
        } else {
            reopenLastGateTaskMutation.mutate(measureId);
        }

        closeMenu();
    };

    const allBulkMeasureActions: Option<MeasureAction>[] = [
        {
            value: true,
            label: translate("setArchive"),
        },
        {
            value: false,
            label: translate("setUnarchive"),
        },
        {
            value: REOPEN_MEASURE,
            label: translate(translationKeys.VDLANG_ADMIN_MEASURES_REOPEN_MEASURE),
        },
        {
            value: CHANGE_RESPONSIBILITY,
            label: translate(translationKeys.VDLANG_ADMIN_MEASURES_CHANGE_RESPONSIBILITY),
        },
    ];

    const visibleBulkActions = allBulkMeasureActions.filter(({ value }) =>
        activeTab !== VisibilityTabs.Archived ? value !== false : value === false,
    );

    const handleSelectedRowsChanged = useMemo(
        () =>
            debounce((newSelectedRows: Row<AdminMeasureDto>[]) => {
                setSelectedMeasuresIds(newSelectedRows.map((r) => r.original.id));
                setSelectedMeasureConfigIds(Array.from(new Set(newSelectedRows.map((r) => r.original.measureConfigId))));
            }, 200),
        [],
    );

    const isRowSelected = useCallback((measureRow: AdminMeasureDto) => selectedMeasuresIds.includes(measureRow.id), [selectedMeasuresIds]);

    const measureMenuOptions = getMeasureMenuOptions(measuresQuery.data ?? [], menuMeasureId, translate);
    const changeTab = (e: SyntheticEvent, v: VisibilityTabs) => {
        setActiveTab(v);
        setSelectedMeasuresIds([]);
        setSelectedMeasureConfigIds([]);
        setSelectedBulkAction(null);
        setFilter("");
    };

    const isApplyButtonDisabled =
        selectedBulkAction == null ||
        selectedMeasuresIds.length === 0 ||
        (selectedBulkAction === CHANGE_RESPONSIBILITY && newAssignedTo == null);

    const onValuestreamChange = (configId: number): void => {
        if (valuestreamDialogMeasureId == null) {
            return;
        }

        updateMeasureMutation.mutate({
            id: valuestreamDialogMeasureId,
            measureConfigId: configId,
        });
    };

    return (
        <Root>
            {valuestreamDialogMeasureId != null && changeValuestreamDialog.isOpen ? (
                <MeasuresChangeValuestreamDialog
                    open={changeValuestreamDialog.isOpen}
                    onClose={changeValuestreamDialog.closeDialog}
                    measure={measuresQuery.data?.find((m) => m.id === valuestreamDialogMeasureId)}
                    onValuestreamSubmit={(configId) => onValuestreamChange(configId)}
                />
            ) : null}
            <RootGrid container direction="column" wrap="nowrap">
                <Grid container direction="column" wrap="nowrap" height="100%">
                    <Menu {...menuProps}>
                        {measureMenuOptions.map((option) => (
                            <MenuItem key={option.id.toString()} onClick={() => handleMenuAction(option.id, menuMeasureId)}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Menu>

                    <Grid item>
                        <TabWrapper>
                            <Tabs value={activeTab} onChange={changeTab}>
                                {[VisibilityTabs.Active, VisibilityTabs.Archived].map((label, index) => (
                                    <Tab key={index} label={translate(label)} value={label} />
                                ))}
                            </Tabs>
                        </TabWrapper>
                    </Grid>
                    <Grid item component={Divider} />
                    <Grid item>
                        <Grid container direction="row" alignItems="center" spacing={1} px={3} py={1.5} flexWrap="nowrap">
                            <Grid item xs={4}>
                                <MeasureBulkSelect
                                    value={selectedBulkAction}
                                    options={visibleBulkActions}
                                    onChange={(option) => setSelectedBulkAction(option)}
                                    count={selectedMeasuresIds.length}
                                />
                            </Grid>
                            {String(selectedBulkAction) === CHANGE_RESPONSIBILITY.toString() && (
                                <Grid item xs={4}>
                                    <MeasuresAssigneeSelect
                                        users={measuresAssigneeCandidates.filter((u) => u !== undefined)}
                                        translate={translate}
                                        value={newAssignedTo}
                                        onChange={setNewAssignedTo}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={2}>
                                <Button color="primary" variant="contained" onClick={applyBulkAction} disabled={isApplyButtonDisabled}>
                                    {translate("apply")}
                                </Button>
                            </Grid>
                            <Grid item container justifyContent="flex-end">
                                <SearchInput translate={translate} onChange={setFilter} searchKey={filter} fullWidth={false} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item component={Divider} />
                    <Grow item>
                        {measuresQuery.isSuccess && usersQuery.isSuccess ? (
                            <BaseTable
                                key={activeTab}
                                fullHeight
                                data={shownMeasures}
                                columns={columns}
                                defaultCanSort
                                disableSortBy={false}
                                itemName="processes"
                                translate={translate}
                                defaultSortBy={[{ id: "clientIid", desc: false }]}
                                noDataText={translate(translationKeys.VDLANG_NO_PROCESSES)}
                                globalFilter={tableFilterValue}
                                isRowSelected={isRowSelected}
                                getRowId={getRowIdForMeasure}
                                onSelectionChanged={handleSelectedRowsChanged}
                            />
                        ) : (
                            <LoadingAnimation />
                        )}
                    </Grow>
                </Grid>
            </RootGrid>
        </Root>
    );
};

export default MeasuresSettings;
