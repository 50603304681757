import { Button, Checkbox, FormControlLabel, MenuItem, Stack, styled } from "@mui/material";
import {
    AdminUserDto,
    GroupDto,
    GroupListDto,
    IdentityProviderType,
    UpdateUserRequest,
    UserStatus,
    UserTier,
    type IdentityProviderDto,
} from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import DangerMenuItem from "../../../components/DangerMenuItem";
import Form from "../../../components/Form";
import Tooltip from "../../../components/Tooltip";
import BaseDialog from "../../../components/dialogues/BaseDialog";
import DialogBackButton from "../../../components/dialogues/DialogBackButton";
import InfoIcon from "../../../components/icons/InfoIcon";
import EmailInput from "../../../components/input/EmailInput";
import Select from "../../../components/input/select/Select";
import { Option } from "../../../components/input/select/types";
import UserEntry from "../../../components/user/UserEntry";
import { useAdminIdentityProviders } from "../../../domain/admin/signon";
import { useAdminTFAReset } from "../../../domain/admin/users";
import { useSeatLimits } from "../../../domain/seat-limit";
import { useCurrentUser } from "../../../domain/users";
import VerifiedUserIllustration from "../../../static/images/verified_user.svg";
import { translationKeys } from "../../../translations/main-translations";
import { GroupSelect } from "./GroupSelect";
import { getUserTierOption, getUserTierOptions } from "./user-utils";

const Image = styled("img")({
    display: "inline-block",
    maxWidth: "100%",
    maxHeigh: "100%",
});

interface EditMemberDialogProps {
    open: boolean;
    onClose: () => void;
    user: AdminUserDto;
    allUsers: AdminUserDto[];
    updateUser: (changes: Partial<UpdateUserRequest>) => void;
    resendUserInvitation: (userId: number) => void;
    inviteUser: (userId: number) => void;
    resetPassword: (userId: number) => void;
    deleteUser: () => void;
    isLoggedInUser: boolean;
    identityProvider: IdentityProviderDto | undefined;
    allGroups: GroupListDto;
}

const EditMemberDialog = ({
    user,
    updateUser,
    allUsers,
    resendUserInvitation,
    inviteUser,
    resetPassword,
    isLoggedInUser,
    deleteUser,
    onClose,
    open,
    identityProvider,
    allGroups,
}: EditMemberDialogProps) => {
    const { t: translate } = useTranslation();
    const identityProvidersQuery = useAdminIdentityProviders();
    const reset2FaMutation = useAdminTFAReset();
    const seatLimits = useSeatLimits();
    const currentUser = useCurrentUser();

    const tfaProviderEnforced =
        identityProvider != null &&
        "twoFactorAuthenticationEnforce" in identityProvider.config &&
        identityProvider.config.twoFactorAuthenticationEnforce;

    const [email, setEmail] = useState(user.email ?? "");
    const [twoFactorAuthenticationEnforce, setTwoFactorAuthenticationEnforce] = useState(
        user.twoFactorAuthenticationEnforce ?? tfaProviderEnforced ?? false,
    );
    const [touched, setTouched] = useState(false);
    const [identityProviderId, setIdentityProviderId] = useState<number | null>(user.identityProviderId ?? null);
    const [tier, setTier] = useState(user.tier ?? UserTier.Light);
    const [groups, setGroups] = useState<number[]>(
        allGroups.filter((group: GroupDto) => group.userIds.includes(user.id)).map((group: GroupDto) => group.id),
    );

    function reset() {
        // Reset local state to initial values as above
        setEmail(user.email ?? "");
        setTwoFactorAuthenticationEnforce(user.twoFactorAuthenticationEnforce ?? tfaProviderEnforced ?? false);
        setTouched(false);
        setIdentityProviderId(user.identityProviderId ?? null);
        setTier(user.tier ?? UserTier.Light);
        setGroups(allGroups.filter((group: GroupDto) => group.userIds.includes(user.id)).map((group: GroupDto) => group.id));
    }

    function handleClose() {
        reset();
        onClose();
    }

    const userTierOptions = getUserTierOptions(translate);

    const toggleActivation = () =>
        updateUser({
            status: user.status === UserStatus.STATUS_INACTIVE ? UserStatus.STATUS_ACTIVE : UserStatus.STATUS_INACTIVE,
        });

    const hasAlreadyExistingMail = allUsers.some((u) => u.email?.toLowerCase() === email.toLowerCase() && u.id !== user.id);

    const isValidMail = email.length > 0 && isEmail(email.trim(), { allow_utf8_local_part: false });
    const isValidChange = touched && isValidMail && !hasAlreadyExistingMail && (groups.length !== 0 || seatLimits.light.hasOpenSeats);

    let emailHelperText = "";
    if (!isValidMail) {
        emailHelperText = translate(translationKeys.VDLANG_INVITE_USER_INVALID_EMAIL);
    }
    if (hasAlreadyExistingMail) {
        emailHelperText = translate(translationKeys.VDLANG_INVITE_USER_ALREADY_EXISTS);
    }

    const submit = () => isValidChange && updateUser({ email, groups, identityProviderId, twoFactorAuthenticationEnforce, tier });

    const handleEmailChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(target.value);
        setTouched(true);
    };

    const handle2FaChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setTwoFactorAuthenticationEnforce(checked);
        setTouched(true);
    };

    const handleIdentityProviderChange = (newProviderSelection: Option<number | null> | null) => {
        setIdentityProviderId(newProviderSelection?.value ?? null);
        setTouched(true);
    };

    const handleGroupChange = (newGroups: number[]) => {
        setGroups(newGroups);
        setTouched(true);
    };

    const handleTierChange = (tier: UserTier) => {
        setTier(tier);
        setTouched(true);
    };

    const isScimUser =
        (user.scimExternalId !== null && user.scimExternalId.length > 0) || (user.scimUserName !== null && user.scimUserName.length > 0);

    const getMenuActions = (isUserSuperAdmin: boolean) => {
        const actions = [];
        const isUserInactive = user.status === UserStatus.STATUS_INACTIVE;
        const isUserInvited = user.status === UserStatus.STATUS_INVITED;

        if (isUserInvited) {
            actions.push(
                <MenuItem key="reinvite" onClick={() => resendUserInvitation(user.id)}>
                    {translate("reinvite")}
                </MenuItem>,
            );
        }
        if (!isUserInactive && !isUserInvited) {
            actions.push(
                <MenuItem key="resetpw" onClick={() => resetPassword(user.id)}>
                    {translate("reset_user_password")}
                </MenuItem>,
            );
        }
        if (!isUserInactive && !isUserInvited && user.twoFactorAuthenticationValidated) {
            actions.push(
                <MenuItem key="reset2fa" onClick={() => reset2FaMutation.mutate(user.id)}>
                    {translate(translationKeys.VDLANG_USER_RESET_2FA)}
                </MenuItem>,
            );
        }
        if (!isLoggedInUser && !isUserSuperAdmin) {
            actions.push(
                <Tooltip disableInteractive title={isScimUser ? translate(translationKeys.VDLANG_USER_ADMIN_SCIM_INFO) : ""}>
                    <span>
                        <MenuItem key="activation" onClick={toggleActivation} disabled={isScimUser}>
                            {translate(isUserInactive ? "reactivate" : "deactivate")}
                        </MenuItem>
                    </span>
                </Tooltip>,
            );
        }

        if (!isLoggedInUser && !isUserSuperAdmin) {
            actions.push(
                // Allow deletion of SCIM users, but show a tooltip that explains that sync might recreate the user
                <Tooltip disableInteractive title={isScimUser ? translate(translationKeys.VDLANG_USER_ADMIN_SCIM_INFO) : ""}>
                    <DangerMenuItem key="deletion" onClick={deleteUser}>
                        {translate(translationKeys.VDLANG_DELETE_USER)}
                    </DangerMenuItem>
                </Tooltip>,
            );
        }
        return actions;
    };

    const actions = [
        <DialogBackButton key="cancel" onClick={handleClose} translate={translate} />,
        <Button key="save" variant="contained" disabled={!isValidChange} onClick={submit}>
            {translate(translationKeys.VDLANG_SAVE)}
        </Button>,
    ];

    const identityProviderOptions = [
        {
            value: null as number | null, // help compiler to properly resolve array type
            label: translate(translationKeys.VDLANG_DEFAULT),
        },
        ...(identityProvidersQuery.data ?? []).map(({ id, name }) => ({
            value: id,
            label: name,
        })),
    ];

    const hasPasswordSignOn = identityProvider?.type === IdentityProviderType.Password;

    return (
        <BaseDialog
            open={open}
            onClose={handleClose}
            title={translate("edit_member")}
            fullWidth
            actions={actions}
            menuActions={getMenuActions(user.tier === UserTier.SuperAdmin)} // Yes this is a cheap check and ignores the permissions but this is fine in this case
        >
            <Form onSubmit={submit}>
                <Stack direction="row" spacing={1} width="max-content">
                    <UserEntry disableGutters disabled user={user} avatarProps={{ size: 20 }} translate={translate} />
                    {!hasPasswordSignOn || user.twoFactorAuthenticationValidated ? (
                        <Tooltip title={translate(translationKeys.VDLANG_USER_2FA_ACTIVATED_HINT)}>
                            <Image src={VerifiedUserIllustration} alt="" />
                        </Tooltip>
                    ) : null}
                </Stack>
                <EmailInput
                    mandatory
                    fullWidth
                    value={email}
                    onChange={handleEmailChange}
                    error={hasAlreadyExistingMail || !isValidMail}
                    helperText={emailHelperText}
                />
                {identityProvidersQuery.isSuccess ? (
                    <Select
                        label={translate(translationKeys.VDLANG_USED_IN_IDENTITY_PROVIDER)}
                        value={identityProviderOptions.find((ip) => ip.value === identityProviderId)}
                        options={identityProviderOptions}
                        onChange={handleIdentityProviderChange}
                        isClearable={identityProviderId !== null}
                        isSearchable
                        menuPortalTarget={document.body}
                    />
                ) : null}
                {hasPasswordSignOn ? (
                    <FormControlLabel
                        key="enforce_2fa"
                        label={translate(
                            tfaProviderEnforced
                                ? translationKeys.VDLANG_USER_ENFORCE_2FA_BY_PROVIDER
                                : translationKeys.VDLANG_USER_ENFORCE_2FA,
                        )}
                        control={
                            <Checkbox name="twoFactorAuthEnforce" checked={twoFactorAuthenticationEnforce} onChange={handle2FaChange} />
                        }
                        disabled={tfaProviderEnforced}
                    />
                ) : null}
                <GroupSelect label={translate(translationKeys.VDLANG_ADMIN_MEMBERS_GROUPS)} onChange={handleGroupChange} value={groups} />
                <Select
                    name="tier"
                    label={
                        <>
                            {`${translate(translationKeys.VDLANG_USER_TIER)} `}
                            <InfoIcon title={translate(translationKeys.VDLANG_USER_TIER_HINT)} />
                        </>
                    }
                    value={getUserTierOption(tier, translate)}
                    onChange={(option) => handleTierChange(option?.value ?? userTierOptions[0].value)}
                    options={userTierOptions}
                    margin="none"
                    menuPortalTarget={document.body}
                    isDisabled={currentUser?.id === user.id && currentUser?.tier === UserTier.Admin}
                />
            </Form>
        </BaseDialog>
    );
};

export default EditMemberDialog;
