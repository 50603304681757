import { Alert, Card, CardActions, CardContent, CardHeader, Grid, Link, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../../components/Form";
import LoadingButton from "../../../components/LoadingButton";
import ConfirmDialog from "../../../components/dialogues/ConfirmDialog";
import CopieableTextField from "../../../components/input/CopieableTextField";
import Select from "../../../components/input/select/Select";
import { useGenerateScimToken, useScimTokenMeta } from "../../../domain/admin/client";
import { useCurrentClient } from "../../../domain/client";
import useDialog from "../../../hooks/useDialog";
import useTimezone from "../../../hooks/useTimezone";
import { translationKeys } from "../../../translations/main-translations";

const periodOptions = [90, 180, 360, 720].map((p) => ({ label: `${p}`, value: p }));

const ScimIntegrations = () => {
    const { t: translate } = useTranslation();

    const { formatDateTime } = useTimezone();

    const [selectedPeriod, setSelectedPeriod] = useState(periodOptions.find(({ value }) => value === 90));

    const generateScimTokenMutation = useGenerateScimToken();

    const getMetaScimToken = useScimTokenMeta();

    const client = useCurrentClient();

    const { isOpen, closeDialog, openDialog } = useDialog();

    const submit = () => {
        openDialog();
    };

    function showConfirmDialog() {
        if (selectedPeriod == null) {
            return;
        }
        generateScimTokenMutation.mutate(selectedPeriod.value);
    }

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={7}>
                <Card>
                    <CardHeader title={translate(translationKeys.VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_SCIM)} />
                    <CardContent>
                        <CopieableTextField
                            label={translate(translationKeys.VDLANG_SCIM_INTEGRATIONS_BASE_PATH)}
                            value={`${document.location.origin}/api/scim/${client.id}/scim/v2`}
                            fullWidth
                        />
                    </CardContent>
                </Card>
                <Card sx={{ mt: 2 }}>
                    <CardHeader title={translate(translationKeys.VDLANG_INTEGRATIONS_TOKEN)} />
                    <CardContent>
                        <Alert severity="warning">{translate(translationKeys.VDLANG_INTEGRATIONS_RENEW_INFO)}</Alert>
                        {getMetaScimToken.isSuccess && getMetaScimToken.data.expiresAt != null ? (
                            <Alert severity="info" sx={{ mt: 2 }}>
                                {`${translate(translationKeys.VDLANG_INTEGRATIONS_EXISTS_EXPIRES_AT)} ${formatDateTime(
                                    getMetaScimToken.data.expiresAt,
                                )}.`}
                            </Alert>
                        ) : null}
                        <Form onSubmit={submit}>
                            <Select
                                label={translate("days")}
                                options={periodOptions}
                                value={selectedPeriod}
                                onChange={(newValue) => newValue != null && setSelectedPeriod(newValue)}
                                menuPortalTarget={document.body}
                            />
                            {generateScimTokenMutation.isSuccess ? (
                                <CopieableTextField
                                    label={translate(translationKeys.VDLANG_INTEGRATIONS_TOKEN)}
                                    value={generateScimTokenMutation.data?.token}
                                    fullWidth
                                />
                            ) : null}
                        </Form>
                    </CardContent>
                    <CardActions>
                        <LoadingButton isLoading={generateScimTokenMutation.isLoading} variant="contained" onClick={submit}>
                            {translate(translationKeys.VDLANG_INTEGRATIONS_RENEW_TOKEN)}
                        </LoadingButton>
                    </CardActions>
                    <ConfirmDialog
                        open={isOpen}
                        onClose={closeDialog}
                        item={translationKeys.VDLANG_INTEGRATIONS_RENEW_TOKEN}
                        translate={translate}
                        title={translate(translationKeys.VDLANG_INTEGRATIONS_TOKEN)}
                        onConfirm={showConfirmDialog}
                    >
                        {translate(translationKeys.VDLANG_INTEGRATIONS_RENEW_INFO)}
                    </ConfirmDialog>
                </Card>
                <Card sx={{ mt: 2 }}>
                    <CardHeader title={translate(translationKeys.VDLANG_SCIM_INTEGRATIONS_SUPPORTED_FIELDS)} />
                    <CardContent>
                        <Typography>{translate(translationKeys.VDLANG_SCIM_INTEGRATIONS_SUPPORTED_FIELDS_HINT)}</Typography>
                        <Typography>
                            <ul>
                                <li>userName</li>
                                <li>active</li>
                                <li>displayName</li>
                                <li>title</li>
                                <li>emails[type eq &quot;work&quot;].value</li>
                                <li>name.givenName</li>
                                <li>name.familyName</li>
                                <li>name.formatted</li>
                                <li>phoneNumbers[type eq &quot;work&quot;].value</li>
                                <li>phoneNumbers[type eq &quot;mobile&quot;].value</li>
                                <li>externalId</li>
                                <li>urn:ietf:params:scim:schemas:extension:enterprise:2.0:User:department</li>
                            </ul>
                        </Typography>
                        <Typography>
                            Active/Inactive state in <strong>Microsoft Entra ID</strong> is not handled correctly. Use one of the following
                            solutions:
                            <ul>
                                <li>
                                    Add <code>?aadOptscim062020</code> to SCIM URL.{" "}
                                    <Link href="https://learn.microsoft.com/en-us/entra/identity/app-provisioning/application-provisioning-config-problem-scim-compatibility#scim-20-compliance-issues-and-status">
                                        Known issues and resolutions with SCIM 2.0 protocol compliance of the Microsoft Entra user
                                        provisioning service
                                    </Link>
                                    .
                                </li>
                                <li>
                                    Set active state field mapping to <code>Switch([IsSoftDeleted], , false, true, true, false)</code>
                                </li>
                            </ul>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default ScimIntegrations;
