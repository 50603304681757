import { styled } from "@mui/material";
import { ReactNode } from "react";
import { getDynamicVirtualizedItemStyles, VIRTUAL_ITEM_BASE_STYLES } from "./helper";

const BaseVirtualItemContainer = styled("div")(() => ({ ...VIRTUAL_ITEM_BASE_STYLES }));

const VirtualItemContainer = (props: { height: number; start: number; children: ReactNode }) => {
    const { height, start, children } = props;

    return <BaseVirtualItemContainer style={getDynamicVirtualizedItemStyles(height, start)}>{children}</BaseVirtualItemContainer>;
};

export default VirtualItemContainer;
