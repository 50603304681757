import { Divider, Paper, Stack, Typography, styled } from "@mui/material";
import { Children, PropsWithChildren } from "react";

const SeatLimitTitle = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
}));

export const SeatLimitContainer = ({ children }: PropsWithChildren) => {
    return (
        <Paper sx={{ mb: 1 }} data-testid="seat-limit-container">
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} px={3} py={1}>
                {Children.map(children, (child, index) =>
                    child !== null ? (
                        <Stack direction="row" flexGrow={1}>
                            {index > 0 && (children as any)[index - 1] !== null ? (
                                <Divider orientation="vertical" flexItem sx={{ mx: 1.5 }} />
                            ) : null}
                            <Divider />
                            {child}
                        </Stack>
                    ) : null,
                )}
            </Stack>
        </Paper>
    );
};

type SeatLimitProps = {
    title: string;
    seats: number;
    maxSeats: number;
};

export const SeatLimit = ({ title, seats, maxSeats }: SeatLimitProps) => {
    return (
        <Stack direction="column" alignItems="start">
            <Stack direction="row" alignItems="end">
                <Typography variant="h5">{seats}</Typography>
                <Typography variant="subtitle2">/{maxSeats}</Typography>
            </Stack>
            <SeatLimitTitle>{title}</SeatLimitTitle>
        </Stack>
    );
};
