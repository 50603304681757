import { zodResolver } from "@hookform/resolvers/zod";
import { Divider, IconButton, Stack, styled, Typography } from "@mui/material";
import { GroupDto, nonNullable, UpdateGroupRequestBody, UserDto, zUpdateGroupRequestBody } from "api-shared";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import SingleUserButton from "../../../components/user/SingleUserButton";

import DeleteRounded from "@mui/icons-material/DeleteRounded";
import { baseDialogClasses } from "../../../components/dialogues/BaseDialog";
import { useUpdateGroupMutation } from "../../../domain/admin/groups";
import { compareUsersByDisplayName } from "../../../lib/sort";
import { translationKeys } from "../../../translations/main-translations";
import UserDialogAvailableUsersColumn from "./UserDialogAvailableUsersColumn";

const MyDialog = styled(ActionItemDialog)(({ theme }) => ({
    [`& .${baseDialogClasses.content}`]: {
        minHeight: 0,
        overflow: "hidden",
        display: "grid",
        gridTemplateColumns: "1fr auto 1fr",
    },
}));

export const UserDialogScrollContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 3, 2, 3),
    overflow: "auto",
    height: "100%",
}));

type UserDialogProps = {
    open: boolean;
    currentUserIds: number[];
    allUsers: UserDto[];
    onClose: () => void;
    currentGroup: GroupDto;
};

export const UserDialog = ({ open, currentUserIds, onClose, allUsers, currentGroup }: UserDialogProps) => {
    const { t } = useTranslation();

    const {
        handleSubmit,
        control,
        formState: { isValid, isDirty },
        setValue,
        watch,
        reset,
    } = useForm<Pick<UpdateGroupRequestBody, "userIds">>({
        mode: "onChange",
        resolver: zodResolver(zUpdateGroupRequestBody.pick({ userIds: true })),
        defaultValues: { userIds: currentUserIds },
    });

    const updateGroupsMutation = useUpdateGroupMutation();

    const onSubmit = handleSubmit((data) => {
        updateGroupsMutation.mutate({ groupId: currentGroup.id, ...currentGroup, userIds: data.userIds }, { onSuccess: handleClose });
    });

    function handleClose() {
        onClose();
        reset({ userIds: currentUserIds });
    }

    const getSelectedList = () => {
        const currentValues = watch("userIds");
        const filteredUserList = currentValues
            .map((item) => allUsers.find((u) => u.id === item) ?? null)
            .filter(nonNullable)
            .toSorted(compareUsersByDisplayName);
        return filteredUserList.map((user) => (
            <Stack key={user.id} direction="row" justifyContent="space-between" alignItems="space-between" py={0.5}>
                <SingleUserButton user={user} translate={t} />
                <IconButton
                    onClick={() =>
                        setValue(
                            "userIds",
                            currentValues.filter((val) => val !== user.id),
                            { shouldValidate: true, shouldDirty: true, shouldTouch: true },
                        )
                    }
                >
                    <DeleteRounded />
                </IconButton>
            </Stack>
        ));
    };

    return (
        <MyDialog
            open={open}
            title={t(translationKeys.VDLANG_ADMIN_GROUPS_MANAGE_USERS_TITLE)}
            primary={t(translationKeys.VDLANG_SAVE)}
            primaryIsTranslated
            onPrimary={onSubmit}
            primaryDisabled={!isValid || !isDirty}
            onClose={handleClose}
            translate={t}
            disableContentPadding
            maxWidth="md"
        >
            <UserDialogAvailableUsersColumn allUsers={allUsers} control={control} onSubmit={onSubmit} />
            <Divider orientation="vertical" flexItem />
            <UserDialogScrollContainer>
                <Typography component="div" fontWeight="medium" color="GrayText" variant="overline" sx={{ paddingTop: 2 }}>
                    {t(translationKeys.VDLANG_ADMIN_GROUPS_ASSIGNED_USERS)}
                </Typography>
                {getSelectedList()}
            </UserDialogScrollContainer>
        </MyDialog>
    );
};
