import { Checkbox, Stack, styled, Typography } from "@mui/material";
import { UpdateGroupRequestBody, UserDto } from "api-shared";
import { useRef, useState } from "react";
import { Controller, type Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Form from "../../../components/Form";
import SearchInput from "../../../components/input/SearchInput";
import SingleUserButton from "../../../components/user/SingleUserButton";

import { useVirtualizer } from "@tanstack/react-virtual";
import VirtualItemContainer from "../../../components/virtual/VirtualItemContainer";
import { useDebounce } from "../../../hooks/useDebounce";
import { compareUsersByDisplayName } from "../../../lib/sort";
import { translationKeys } from "../../../translations/main-translations";
import { UserDialogScrollContainer } from "./UserDialog";

const UserDialogHeaderWrapper = styled("div")(({ theme }) => ({
    position: "sticky",
    top: 0,
    zIndex: 1000,
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
}));

type UserDialogAvailableUsersColumnProps = {
    allUsers: UserDto[];
    control: Control<Pick<UpdateGroupRequestBody, "userIds">>;
    onSubmit: () => void;
};

function UserDialogAvailableUsersColumn({ allUsers, control, onSubmit }: Readonly<UserDialogAvailableUsersColumnProps>) {
    const { t } = useTranslation();

    const [searchKey, setSearchKey] = useState("");
    const debouncedSearchKey = useDebounce(searchKey);

    const users = allUsers
        .filter((user) => user.displayname?.toLowerCase().includes(debouncedSearchKey.toLowerCase()))
        .toSorted(compareUsersByDisplayName);

    const scrollElementRef = useRef<HTMLDivElement | null>(null);
    const rowVirtualizer = useVirtualizer({
        count: users.length,
        getScrollElement: () => scrollElementRef.current,
        estimateSize: () => 50,
    });

    const totalSize = rowVirtualizer.getTotalSize();

    function toggleUser(list: number[], userId: number): number[] {
        if (!list.includes(userId)) {
            return [...list, userId];
        }
        return list.filter((u) => u !== userId);
    }

    return (
        <UserDialogScrollContainer ref={scrollElementRef}>
            <UserDialogHeaderWrapper>
                <Typography fontWeight="medium" color="GrayText" variant="overline">
                    {t(translationKeys.VDLANG_ADMIN_GROUPS_ALL_USERS_TITLE)}
                </Typography>
                <SearchInput onChange={(input) => setSearchKey(input)} searchKey={searchKey} translate={t} />
            </UserDialogHeaderWrapper>
            <Form onSubmit={onSubmit} style={{ margin: 0 }}>
                <Controller
                    name="userIds"
                    control={control}
                    render={({ field: { value, ref, onChange } }) => (
                        <div style={{ height: totalSize, position: "relative" }}>
                            {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                                const user = users[virtualItem.index];
                                return (
                                    <VirtualItemContainer key={virtualItem.key} height={virtualItem.size} start={virtualItem.start}>
                                        <Stack direction="row" justifyContent="space-between" alignItems="space-between" py={0.5}>
                                            <SingleUserButton user={user} translate={t} />
                                            <Checkbox
                                                size="small"
                                                checked={value.includes(user.id)}
                                                onChange={() => onChange(toggleUser(value, user.id))}
                                                inputRef={ref}
                                            />
                                        </Stack>
                                    </VirtualItemContainer>
                                );
                            })}
                        </div>
                    )}
                />
            </Form>
        </UserDialogScrollContainer>
    );
}

export default UserDialogAvailableUsersColumn;
